import { Popper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAllCarrocerias,
  getAllMarcas,
  getAllModelos,
  getAllTransmisiones,
} from "../api";

const FilterBarHome = () => {
  const [filtros, setFiltros] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          marcasResponse,
          carroceriasResponse,
          modelosResponse,
          transmisionesResponse,
          //ubicacionesResponse,
        ] = await Promise.all([
          getAllMarcas(),
          getAllCarrocerias(),
          getAllModelos(),
          getAllTransmisiones(),
          //getAllUbicaciones(),
        ]);

        // Filtrar los valores vacíos y ordenar alfabéticamente antes de extraer los nombres
        const marcasOptions =
          marcasResponse.data?.map(({ name }) => name) || [];
        const carroceriasOptions =
          carroceriasResponse.data?.map(({ name }) => name) || [];
        const modelosOptions =
          modelosResponse.data
            ?.filter(({ name }) => name !== "")
            .map(({ name }) => name) || [];
        const transmisionesOptions =
          transmisionesResponse.data
            ?.filter(({ name }) => name !== "")
            .map(({ name }) => name) || [];
        const ubicacionesOptions = ["Centenario", "Tlalnepantla"];
        // Definir los filtros iniciales con los datos obtenidos
        const filtrosIniciales = [
          { label: "Marca", options: marcasOptions, open: false },
          { label: "Carroceria", options: carroceriasOptions, open: false },
          { label: "Modelo", options: modelosOptions, open: false },
          { label: "Transmision", options: transmisionesOptions, open: false },
          { label: "Ubicacion", options: ubicacionesOptions, open: false },
          // Puedes agregar aquí otros filtros estáticos o dinámicos según sea necesario
        ];

        setFiltros(filtrosIniciales);
      } catch (error) {
        console.error("Error fetching data: ", error);
        // Manejar el error como consideres necesario
      }
    };

    fetchData();
  }, []);

  const handleClickOption = (filterLabel, option) => {
    // Navega al componente CarGallery pasando el filtro y el valor como estado
    navigate("/comprar-auto", {
      state: { filter: filterLabel, value: option },
    });
  };

  const handleMouseEnter = (index) => (event) => {
    setFiltros((previos) => {
      previos[index] = { ...previos[index], open: true };
      return [...previos]; // Se devuelve una nueva copia del array para asegurar la actualización del estado
    });
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMouseLeave = (index) => () => {
    setFiltros((previos) => {
      previos[index] = { ...previos[index], open: false };
      return [...previos]; // Se devuelve una nueva copia del array
    });
    setAnchorEl(null);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  return (
    <div className="filter-bar">
      <div className="filter-categories">
        <a className="filter-text">Explora:</a>
        {filtros.map(({ label, options, open }, index) => (
          <div
            key={label} // Usamos label como key porque asumimos que son únicos
            onMouseEnter={handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave(index)}
          >
            <div className="filter-category">
              <a>{label}</a>
            </div>
            <Popper
              className="popper"
              id={`${label}-${index}`}
              open={open}
              anchorEl={anchorEl}
            >
              {options.map((option) => (
                <a
                  key={option}
                  onClick={() => handleClickOption(label, option)}
                >
                  {capitalizeFirstLetter(option)}
                </a>
              ))}
            </Popper>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterBarHome;
