// Importaciones necesarias incluyendo React, Material-UI, componentes personalizados y funciones de la API
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Button, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getCountCars, getSomeCars } from "../api";
import CarCard from "./CarCard";
import FilterBar from "./FilterDrawer";
import SpinnerLlanta from "./SpinnerLlanta";

const CarGallery = () => {
  // Estado inicial y funciones para manejar los datos y UI de la galería
  const [carsData, setCarsData] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const resultsPerPage = 12;
  const [selectedFilters, setSelectedFilters] = useState({
    Ubicacion: [],
    Modelo: [],
    Transmision: [],
    Marca: [],
    Carroceria: [],
    Precio: [],
    Km: [],
  });
  const location = useLocation();
  const filter = location.state?.filter;
  const value = location.state?.value;
  const navigate = useNavigate();

  useEffect(() => {
    // Solo actualiza selectedFilters si filter y value son válidos
    if (
      filter &&
      value !== undefined &&
      Object.keys(selectedFilters).includes(filter)
    ) {
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        [filter]: Array.isArray(value) ? value : [value], // Asegúrate de que el valor sea un array
      }));
    }
  }, [filter, value]);

  // Manejo de filtros seleccionados desde el componente FilterFolders
  const handleFilters = (filters) => {
    setSelectedFilters(filters);
    setCurrentPage(1); // Reinicia la paginación al aplicar nuevos filtros
  };

  // Reiniciar filtros y limpiar el estado de la navegación
  const handleResetFilters = () => {
    setSelectedFilters({});
    navigate("/comprar-Auto", { replace: true });
  };

  // Efectos secundarios para cargar datos de los coches y calcular el total de resultados basados en filtros
  useEffect(() => {
    const fetchCars = async () => {
      setIsLoading(true);
      try {
        const response = await getSomeCars(
          currentPage,
          resultsPerPage,
          selectedFilters,
        );
        setCarsData(response);
      } catch (error) {
        console.error("Error fetching cars:", error);
        setCarsData([]);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchTotalResults = async () => {
      try {
        const total = await getCountCars(selectedFilters);
        setTotalResults(total);
      } catch (error) {
        console.error("Error fetching total car count:", error);
        setTotalResults(0);
      }
    };

    fetchCars();
    fetchTotalResults();
  }, [currentPage, selectedFilters]);

  // Calcula los resultados mostrados basándose en la página actual y el total de resultados
  const displayedResults = Math.min(currentPage * resultsPerPage, totalResults);

  // Funciones para controlar la visibilidad de los filtros, navegación de página y búsqueda
  const toggleFilters = () => setShowFilters(!showFilters);
  const nextPage = () =>
    setCurrentPage(
      currentPage < Math.ceil(totalResults / resultsPerPage)
        ? currentPage + 1
        : currentPage,
    );
  const previousPage = () => setCurrentPage(Math.max(currentPage - 1, 1));

  // Renderizado del componente, incluyendo la barra de filtros, resultados de búsqueda, y paginación
  return (
    <div>
      {
        //<SearchBar onSearch={handleSearch} />
      }
      <div className="carGallery">
        {/* Barra superior */}
        <div className="barraSuperior">
          <div className="filter">
            <IconButton
              color="rojoYBlanco"
              onClick={toggleFilters}
              style={{
                transform: showFilters ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            >
              <FilterListIcon />
            </IconButton>
            <Typography color="blancoYNegro.light">{`Filtros`}</Typography>
          </div>
          <Typography color="blancoYNegro.light">{`${displayedResults} de ${totalResults} en total encontrados`}</Typography>
        </div>

        {/* Contenido principal */}
        <div className="container">
          {showFilters && (
            <FilterBar
              onApplyFilters={handleFilters}
              showFilters={true}
              onResetFilters={handleResetFilters}
            />
          )}
          {isLoading ? (
            <SpinnerLlanta />
          ) : carsData.length > 0 ? (
            <div className="cardsContainer">
              {carsData.map((car, index) => (
                <CarCard
                  key={index}
                  imgURL={car.imgURL}
                  title={car.title}
                  description={car.description}
                  id={car.id}
                />
              ))}
            </div>
          ) : (
            <Typography style={{ textAlign: "center", marginTop: "20px" }}>
              Carros con estos filtros no han sido encontrados. Aplica nuevos
              filtros.
            </Typography>
          )}
        </div>

        {/* Navegación de página */}
        <div className="navPages">
          <Button onClick={previousPage} disabled={currentPage === 1}>
            <ArrowBackIosNewIcon
              style={{ color: currentPage === 1 ? "#b2b2b2" : "#bd2128" }}
            />
          </Button>
          <Typography variant="body1" style={{ margin: "0 20px" }}>
            {currentPage}
          </Typography>
          <Button
            onClick={nextPage}
            disabled={currentPage >= Math.ceil(totalResults / resultsPerPage)}
          >
            <ArrowForwardIosIcon
              style={{
                color:
                  currentPage >= Math.ceil(totalResults / resultsPerPage)
                    ? "#b2b2b2"
                    : "#bd2128",
              }}
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CarGallery;
