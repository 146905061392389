import {
  Button,
  Card,
  CardContent,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const SignInForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // If user is logged in, redirect to /admin/manager
        navigate("/admin/manager");
      }
    }); // Cleanup the listener when the component unmounts
    return unsubscribe;
  }, [navigate]);

  const handleSignIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/admin/manager");
    } catch (error) {
      console.error("Error signing in:", error);
      setError(error.message);
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card sx={{ maxWidth: 400, boxShadow: 3 }}>
        <CardContent style={{ textAlign: "center" }}>
          {/* Logo */}
          <img
            src="/logo-01.png"
            alt="Logo"
            style={{ maxWidth: "200px", marginBottom: "20px" }}
          />

          <Typography
            variant="h5"
            component="h2"
            style={{ marginBottom: "20px" }}
          >
            Sign In
          </Typography>

          <TextField
            label="Email"
            variant="outlined"
            color="rojoYBlanco"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            color="rojoYBlanco"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            variant="contained"
            color="rojoYBlanco"
            fullWidth
            style={{ marginTop: "20px" }}
            onClick={handleSignIn}
          >
            Sign In
          </Button>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            message={error}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          />
        </CardContent>
      </Card>
    </div>
  );
};

export default SignInForm;
