import { Typography } from "@mui/material";
import React from "react";
import AdminNavbar from "../../components/AdminNavBar";
import CarGalleryEdit from "../../components/CarGalleryEdit";

const EditarAuto = () => {
  return (
    <div>
      <AdminNavbar />
      <div className="content">
        <Typography fontSize="2rem">Editar Auto</Typography>
        <div>
          <CarGalleryEdit />
        </div>
      </div>
    </div>
  );
};
export default EditarAuto;
