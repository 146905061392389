// src/pages/admin/AdminLandingPage.js
import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getCountCars } from "../../api";
import AdminNavbar from "../../components/AdminNavBar";

const AdminLandingPage = () => {
  const [existenceCarCount, setExistenceCarCount] = useState(0);
  const [totalCarCount, setTotalCarCount] = useState(0);

  useEffect(() => {
    const fetchExistenceCarCount = async () => {
      try {
        setExistenceCarCount(await getCountCars());
        setTotalCarCount(await getCountCars(0, true));
      } catch (error) {
        console.error("Error fetching total car count:", error);
        setExistenceCarCount(0);
        setTotalCarCount(0);
      }
    };
    fetchExistenceCarCount();
  }, []);

  return (
    <div>
      <AdminNavbar />
      <Container className="content">
        <Typography variant="h4" style={{ margin: "20px" }}>
          Bienvenido al dashboard admin
        </Typography>

        <Box display="flex" justifyContent="space-around" flexWrap="wrap">
          <Card style={{ minWidth: 275, margin: "10px" }}>
            <CardContent>
              <Typography variant="h5" component="h2">
                Cantidad de Carros
              </Typography>
              <Typography variant="body2" component="p">
                {totalCarCount}
              </Typography>
            </CardContent>
          </Card>

          <Card style={{ minWidth: 275, margin: "10px" }}>
            <CardContent>
              <Typography variant="h5" component="h2">
                Cantidad de Carros en Existencia
              </Typography>
              <Typography variant="body2" component="p">
                {existenceCarCount}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </div>
  );
};

export default AdminLandingPage;
