import { useTheme } from "@emotion/react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SpeedIcon from "@mui/icons-material/Speed";
import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaCarSide } from "react-icons/fa";
import { GiGearStickPattern } from "react-icons/gi";
import {
  getAllCarrocerias,
  getAllMarcas,
  getAllModelos,
  getAllTransmisiones,
  getKmMax,
  getKmMin,
  getPrecioMax,
  getPrecioMin,
} from "../api"; // Asegúrate de que estas funciones estén correctamente definidas y exportadas en tu archivo API.
import RangeSelector from "./RangeSelector";
import SpinnerLlanta from "./SpinnerLlanta";

const FilterDrawer = ({ onApplyFilters, onResetFilters }) => {
  const theme = useTheme();
  const [open, setOpen] = useState({});
  const [filters, setFilters] = useState([]);
  const initialState = {
    Ubicacion: [],
    Modelo: [],
    Transmision: [],
    Marca: [],
    Carroceria: [],
    Precio: [],
    Km: [],
  };
  const [selectedFilters, setSelectedFilters] = useState(initialState);
  const [maxPrice, setMaxPrice] = useState();
  const [maxKm, setMaxKm] = useState();
  const [minPrice, setMinPrice] = useState(0);
  const [minKm, setMinKm] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Comenzar a cargar
      try {
        const [
          //ubicaciones,
          modelos,
          transmisiones,
          marcas,
          carrocerias,
          precioMax,
          kmMax,
          precioMin,
          kmMin,
        ] = await Promise.all([
          //getAllUbicaciones(),
          getAllModelos(),
          getAllTransmisiones(),
          getAllMarcas(),
          getAllCarrocerias(),
          getPrecioMax(),
          getKmMax(),
          getPrecioMin(),
          getKmMin(),
        ]);

        const marcasOptions = marcas.data.map(({ name }) => name);
        const carroceriasOptions = carrocerias.data.map(({ name }) => name);
        //const ubicacionesOptions = ubicaciones.data.map(({ name }) => name);
        const ubicacionesOptions = ["Centenario", "Tlalnepantla"];
        const modelosOptions = modelos.data.map(({ name }) => name);
        const transmisionesOptions = transmisiones.data.map(({ name }) => name);
        setMaxPrice(precioMax[0].name || 4000000);
        setMaxKm(kmMax[0].name || 500000);
        setMinPrice(precioMin[0].name || 0);
        setMinKm(precioMin[0].name || 0);

        // Configuración de los filtros con los datos obtenidos
        setFilters([
          {
            icon: <DriveEtaIcon color="rojoYBlanco" />,
            text: "Marca",
            subCategories: marcasOptions,
          },
          {
            icon: (
              <FaCarSide
                color="rojoYBlanco"
                style={{ color: "var(--secondaryColor)" }}
                size={25}
              />
            ),
            text: "Carroceria",
            subCategories: ["Todos", ...carroceriasOptions],
          },
          {
            icon: (
              <GiGearStickPattern
                color="rojoYBlanco"
                style={{ color: "var(--secondaryColor)" }}
                size={25}
              />
            ),
            text: "Transmision",
            subCategories: ["Todos", ...transmisionesOptions],
          },
          {
            icon: <CalendarTodayIcon color="rojoYBlanco" />,
            text: "Modelo",
            subCategories: ["Todos", ...modelosOptions],
          },
          {
            icon: <LocationOnIcon color="rojoYBlanco" />,
            text: "Ubicacion",
            subCategories: ["Todos", ...ubicacionesOptions],
          },
        ]);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <SpinnerLlanta />; // Muestra el spinner mientras los datos están cargando
  }

  const handleClick = (text) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [text]: !prevOpen[text],
    }));
  };

  const handleToggle = (category, value) => {
    const categoryFilters = filters
      .find((filter) => filter.text === category)
      .subCategories.slice(); // Obtiene todas las subcategorías
    let newSelected = [...selectedFilters[category]];

    if (value === "Todos") {
      // Si "Todos" está seleccionado/deseleccionado, actualiza todas las opciones
      if (
        newSelected.includes("Todos") ||
        newSelected.length === categoryFilters.length
      ) {
        // Si "Todos" ya estaba seleccionado o si todas las opciones estaban seleccionadas, deselecciona todo
        newSelected = [];
      } else {
        // Si no, selecciona todas las opciones
        newSelected = categoryFilters.slice();
      }
    } else {
      // Para una opción específica
      const index = newSelected.indexOf(value);
      if (index > -1) {
        newSelected.splice(index, 1); // Deselecciona
      } else {
        newSelected.push(value); // Selecciona
      }

      // Verifica si después de esta operación todas las opciones están seleccionadas, incluye "Todos"
      if (newSelected.length === categoryFilters.length) {
        newSelected = categoryFilters.slice(); // Asegúrate de que "Todos" esté seleccionado
      } else if (newSelected.includes("Todos")) {
        // Si "Todos" estaba seleccionado pero ahora no todas las opciones están seleccionadas, quita "Todos"
        newSelected = newSelected.filter((item) => item !== "Todos");
      }
    }

    setSelectedFilters({
      ...selectedFilters,
      [category]: newSelected,
    });
  };

  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

  const handleReturnFilters = () => {
    onApplyFilters(selectedFilters); // Envía los filtros seleccionados al padre
  };

  const handlePriceRangeChange = (newRange) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      Precio: [newRange.min, newRange.max], // Actualiza el rango de precios directamente en selectedFilters
    }));
  };

  const handleKmRangeChange = (newRange) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      Km: [newRange.min, newRange.max],
    }));
  };

  const handleResetFilters = () => {
    setSelectedFilters(initialState); // Restablece los filtros al estado inicial
    onApplyFilters(initialState); // Envía los filtros reiniciados al padre para que también actualice su estado
    onResetFilters();
  };

  return (
    <div
      style={{
        minWidth: 250,
        maxWidth: 400,
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <List>
        {filters.map(({ icon, text, subCategories }, index) => (
          <React.Fragment key={text}>
            <ListItem
              onClick={() => handleClick(text)}
              sx={{ cursor: "pointer" }}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={text} />
              <ListItemIcon>
                {open[text] ? (
                  <ExpandLessIcon color="rojoYBlanco" />
                ) : (
                  <ExpandMoreIcon color="rojoYBlanco" />
                )}
              </ListItemIcon>
            </ListItem>
            <Collapse in={open[text]} unmountOnExit>
              <List component="div" disablePadding>
                {subCategories.map((subCategory, subIndex) => (
                  <ListItem key={subIndex} sx={{ pl: 4 }}>
                    <ListItemText
                      primary={capitalizeFirstLetter(subCategory)}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        color="rojoYBlanco"
                        edge="end"
                        onChange={() => handleToggle(text, subCategory)} // Asegúrate de pasar la categoría correcta
                        checked={selectedFilters[text].includes(subCategory)}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Collapse>
            {index < filters.length - 1 && <Divider />}
          </React.Fragment>
        ))}
        <React.Fragment>
          <Divider />
          <ListItem
            onClick={() => handleClick("Precio")}
            sx={{ cursor: "pointer" }}
          >
            <ListItemIcon>
              <AttachMoneyIcon color="rojoYBlanco" />
            </ListItemIcon>
            <ListItemText primary="Precio" />
            <ListItemIcon>
              {open["Precio"] ? (
                <ExpandLessIcon color="rojoYBlanco" />
              ) : (
                <ExpandMoreIcon color="rojoYBlanco" />
              )}
            </ListItemIcon>
          </ListItem>
          <Collapse in={open["Precio"]} unmountOnExit>
            <RangeSelector
              onRangeChange={handlePriceRangeChange || (() => {})}
              maxValue={maxPrice}
              minValue={minPrice}
              unit="$"
              step={10000}
            />
          </Collapse>
          <Divider />
          <ListItem
            onClick={() => handleClick("Km")}
            sx={{ cursor: "pointer" }}
          >
            <ListItemIcon>
              <SpeedIcon color="rojoYBlanco" />
            </ListItemIcon>
            <ListItemText primary="Km" />
            <ListItemIcon>
              {open["Km"] ? (
                <ExpandLessIcon color="rojoYBlanco" />
              ) : (
                <ExpandMoreIcon color="rojoYBlanco" />
              )}
            </ListItemIcon>
          </ListItem>
          <Collapse in={open["Km"]} unmountOnExit>
            <RangeSelector
              onRangeChange={handleKmRangeChange || (() => {})}
              maxValue={maxKm}
              minValue={minKm}
              unit=""
              step={10000}
            />
          </Collapse>
        </React.Fragment>
      </List>
      <Button
        variant="outlined"
        color="negroYBlanco"
        onClick={handleResetFilters}
        style={{ margin: "10px 0" }}
      >
        Reiniciar Filtros
      </Button>
      <Button
        variant="contained"
        color="rojoYBlanco"
        onClick={handleReturnFilters}
        style={{
          marginTop: "10px",
        }}
      >
        Aplicar Filtros
      </Button>
    </div>
  );
};

export default FilterDrawer;
