import {
  Button,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteCarImages,
  getAllCarrocerias,
  getAllColores,
  getAllCombustibles,
  getAllMarcas,
  getAllModelos,
  getAllSubmarcas,
  getAllTracciones,
  getAllTransmisiones,
  getAllUbicaciones,
  getAllVersiones,
  getCarById,
  updateCar,
} from "../../api";
import { initialCarState } from "../../carState";
import AdminNavbar from "../../components/AdminNavBar";
import AutoCompleteField from "../../components/AutoCompleteField";
import FileInput from "../../components/FileInput";
import SpinnerLlanta from "../../components/SpinnerLlanta";
import { storage } from "../../firebase/Firebase";

const EditarAutoById = () => {
  const { id } = useParams(); // Obtiene el ID del auto desde la URL
  const [car, setCar] = useState(initialCarState);
  const [originalCarData, setOriginalCarData] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [returning, setReturning] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [imagesToErase, setImagesToErase] = useState(car.images || []);

  //Autocompletado de Textfields, aqui se almacenaran todos los valores posibles para cada campo
  const [opciones, setOpciones] = useState({
    marca: [],
    submarca: [],
    version: [],
    modelo: [],
    color: [],
    transmision: [],
    combustible: [],
    traccion: [],
    ubicacion: [],
    carroceria: [],
    // Añadir más campos si es necesario
  });

  // Lista de funciones para solicitar datos de la API
  const funcionesAPI = [
    getAllMarcas,
    getAllSubmarcas,
    getAllModelos,
    getAllVersiones,
    getAllColores,
    getAllTransmisiones,
    getAllCombustibles,
    getAllTracciones,
    getAllUbicaciones,
    getAllCarrocerias,
  ];

  // Lista de claves para actualizar en el estado 'opciones'
  const claves = [
    "marca",
    "submarca",
    "modelo",
    "version",
    "color",
    "transmision",
    "combustible",
    "traccion",
    "ubicacion",
    "carroceria",
  ];

  const handleAutocompleteChange = (name) => (value) => {
    setCar((prevCar) => ({
      ...prevCar,
      general: { ...prevCar.general, [name]: value },
    }));
  };

  const createTextFields = (data, parentKey = "") => {
    return Object.entries(data).map(([key, value]) => {
      const switchFields = ["equipamiento", "seguridad"]; // Definir cuáles categorías deben usar switches

      if (typeof value === "object" && value !== null) {
        return (
          <div className="detallesAdicionales" key={key}>
            <Typography variant="h6" style={{ margin: "10px 0" }}>
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </Typography>
            <div className="subcampos">{createTextFields(value, key)}</div>
          </div>
        );
      } else {
        const fullName = parentKey ? `${parentKey}.${key}` : key;
        if (switchFields.includes(parentKey)) {
          // Convertir el valor de "Si" o "No" a true o false para el Switch
          return (
            <FormControlLabel
              control={
                <Switch
                  checked={!!value}
                  onChange={(e) => handleSwitchChange(e, fullName)}
                  name={fullName}
                  color="rojoYBlanco"
                />
              }
              label={key.charAt(0).toUpperCase() + key.slice(1)}
              key={fullName}
            />
          );
        } else {
          return (
            <TextField
              className="textField"
              key={fullName}
              name={fullName}
              label={key.charAt(0).toUpperCase() + key.slice(1)}
              value={value}
              onChange={handleChange}
              style={{ margin: "5px 0" }}
              color="rojoYBlanco"
            />
          );
        }
      }
    });
  };

  // Función modificada para manejar cambios en el Checkbox
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCar((prevState) => ({
      ...prevState,
      general: {
        ...prevState.general,
        [name]: checked,
      },
    }));
  };

  // Función modificada para manejar cambios en el Checkbox
  // Modifica handleSwitchChange para manejar la actualización del estado
  const handleSwitchChange = (event, name) => {
    const { checked } = event.target;
    const value = checked ? 1 : 0; // Ajuste para mantener coherencia con los datos numéricos
    const keys = name.split("."); // Divide el nombre para gestionar campos anidados

    setCar((prevState) => ({
      ...prevState,
      [keys.length > 1 ? "detallesAdicionales" : "general"]: {
        ...prevState[keys.length > 1 ? "detallesAdicionales" : "general"],
        [keys[0]]:
          keys.length > 1
            ? { ...prevState.detallesAdicionales[keys[0]], [keys[1]]: value }
            : value,
      },
    }));
  };

  // Adaptar handleChange para manejar cambios en campos anidados
  const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split("."); // Divide el nombre para obtener las claves de los campos anidados
    setCar((prevState) => ({
      ...prevState,
      [keys.length > 1 ? "detallesAdicionales" : "general"]: {
        ...prevState[keys.length > 1 ? "detallesAdicionales" : "general"],
        [keys[0]]:
          keys.length > 1
            ? { ...prevState.detallesAdicionales[keys[0]], [keys[1]]: value } // Actualiza el valor del campo anidado
            : value,
      },
    }));
  };

  function capitalizeWords(string) {
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Mostrar indicador de carga

    // Llama a la API para eliminar las imágenes si es necesario
    if (imagesToErase.length > 0) {
      try {
        await deleteCarImages(id, imagesToErase);
      } catch (error) {
        console.error("Error al eliminar imágenes:", error);
        // Manejar el error como consideres necesario
      }
    }

    // Comparar car y originalCarData para ver si hay cambios
    if (
      JSON.stringify(car) === JSON.stringify(originalCarData) &&
      selectedFiles.length === 0
    ) {
      setSnackbarMessage("No se realizaron cambios en la información.");
      setSnackbarOpen(true);
      setLoading(false);
      return; // Salir de la función si no hay cambios
    }

    try {
      // Prepare car data for the API request
      const carData = {
        ...car.general,
        // Propaga cada campo anidado individualmente
        ...car.detallesAdicionales.generales,
        ...car.detallesAdicionales.exterior,
        ...car.detallesAdicionales.interior,
        ...car.detallesAdicionales.equipamiento,
        ...car.detallesAdicionales.seguridad,
      };

      Object.entries(carData).forEach(([key, value]) => {
        if (typeof value === "string") {
          // Capitaliza la primera letra de cada palabra en la cadena
          carData[key] = capitalizeWords(value);
        }
      });

      // Upload images to Firebase Storage and get their URLs
      const imageUrls =
        selectedFiles.length > 0
          ? await Promise.all(selectedFiles.map((file) => uploadImage(file)))
          : [];

      // Add image URLs to carData
      carData.images = imageUrls.filter((url) => url != null);

      // Send carData to the backend server
      await updateCar(id, carData);

      setLoading(false);
      setReturning(true);
      setSnackbarMessage("Carro ha sido Actualizado Correctamente!");
      setSnackbarOpen(true);

      setTimeout(() => {
        setReturning(false);
        navigate("/admin/editar-auto");
      }, 3000);
    } catch (error) {
      console.error("Error adding car: ", error);
      setSnackbarMessage("Error adding car: " + error.message);
    } finally {
      setSnackbarOpen(true);
      setLoading(false);
    }
  };

  const uploadImage = async (file) => {
    const timestamp = Date.now();
    // Construir el nuevo nombre del archivo basado en el formato deseado
    const newFileName = `${car.general.marca}-${car.general.submarca}-${timestamp}-${file.name}`;
    const storageRef = ref(storage, `cars/${newFileName}`);
    try {
      const snapshot = await uploadBytes(storageRef, file);
      return getDownloadURL(snapshot.ref);
    } catch (error) {
      console.error("Error uploading file: ", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchCarData = async () => {
      setLoading(true);
      try {
        const fetchedCarData = await getCarById(id); // Asumiendo que esta función te retorna los datos del carro
        setCar(fetchedCarData);
        setOriginalCarData(fetchedCarData); // Almacena la información original
      } catch (error) {
        setError("Error al cargar la información del auto");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    const cargarOpciones = async () => {
      try {
        for (const [i, funcionAPI] of funcionesAPI.entries()) {
          (async function () {
            const respuesta = await funcionAPI();
            if (respuesta && Array.isArray(respuesta.data)) {
              let data = respuesta.data.filter(
                (item) => item.name.trim() !== "",
              );
              setOpciones((prev) => ({
                ...prev,
                [claves[i]]: prev[claves[i]].length ? prev[claves[i]] : data,
              }));
            } else {
              console.error(`Respuesta no válida de la API para ${claves[i]}`);
              // Manejar adecuadamente una respuesta inesperada o falta de datos
            }
          })();
        }
      } catch (error) {
        console.error("Error al cargar opciones:", error);
      }
    };

    fetchCarData();
    cargarOpciones();
  }, [id]);

  const handleImageKeepChange = (event, imgURL) => {
    if (event.target.checked) {
      setImagesToErase((prev) => [...prev, imgURL]); // Añade la URL al estado si se selecciona
    } else {
      setImagesToErase((prev) => prev.filter((url) => url !== imgURL)); // La elimina del estado si se deselecciona
    }
  };

  return (
    <div>
      <AdminNavbar />
      <div className="content">
        {returning ? (
          // Contenido o componente mostrado cuando returning es true
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "2rem",
              flexDirection: "column",
              padding: "2rem",
            }}
          >
            <Typography fontSize={"3rem"}>
              Espera, estamos estacionando el coche en la Base de datos.
            </Typography>
            <img
              src="/images/Estacionamiento.jpg"
              alt="Estacionando coche en la BDD"
              style={{ height: "auto", width: "70%" }}
            />
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={() => setSnackbarOpen(false)}
              message={snackbarMessage}
            />
          </div>
        ) : loading ? (
          // Contenido o componente mostrado cuando loading es true
          <SpinnerLlanta />
        ) : error ? (
          <div>Error: {error}</div>
        ) : (
          <div>
            <Typography fontSize="2rem">Editar Auto By Id</Typography>
            <div className="agregarCoche">
              <form onSubmit={handleSubmit}>
                <div>
                  <div>
                    <h2>General</h2>
                    <div className="textFields">
                      {Object.keys(car.general).map((key) => {
                        if (
                          key !== "km" &&
                          key !== "existence" &&
                          key !== "precio"
                        ) {
                          return (
                            <AutoCompleteField
                              key={`${key}`}
                              label={key.charAt(0).toUpperCase() + key.slice(1)}
                              options={opciones[key] || []} // Asegúrate de que 'opciones[key]' no sea undefined
                              value={car.general[key] || ""}
                              onChange={(v) => handleAutocompleteChange(key)(v)}
                            />
                          );
                        }
                        return null; // No renderizar nada para 'km'
                      })}
                      <TextField
                        className="textField"
                        key={"km"}
                        name={"km"}
                        label={"Km"}
                        value={car.general.km}
                        onChange={handleChange}
                      />
                      <TextField
                        className="textField"
                        key={"precio"}
                        name={"precio"}
                        label={"Precio"}
                        value={car.general.precio}
                        onChange={handleChange}
                      />
                      <FormControlLabel
                        className="textField"
                        control={
                          <Checkbox
                            checked={car.general.existence}
                            onChange={handleCheckboxChange}
                            name="existence"
                            color="rojoYBlanco"
                          />
                        }
                        label="Existencia"
                      />
                    </div>
                  </div>
                  <div>
                    <h2>Detalles Adicionales</h2>
                    <div className="textFields2">
                      {createTextFields(car.detallesAdicionales)}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <div className="existing-images">
                    <Typography variant="h6">
                      Seleccionar imagenes a borrar
                    </Typography>
                    <div
                      style={{
                        height: "auto",
                        width: "100%",
                        border: "1px solid #e6e6e6",
                        borderRadius: "8px",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "baseline", // Centra el contenido horizontalmente
                        alignItems: "center", // Centra el contenido verticalmente
                      }}
                    >
                      {car.photos?.map((imgURL, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            margin: "10px",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={imgURL}
                            alt={`Car ${index}`}
                            style={{ width: "100px", height: "auto" }}
                          />
                          <Checkbox
                            checked={imagesToErase.includes(imgURL)}
                            onChange={(e) => handleImageKeepChange(e, imgURL)}
                            color="primary"
                          />
                        </div>
                      ))}
                    </div>
                  </div>

                  <FileInput
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                  />
                </div>
                <Button
                  variant="contained"
                  className="button"
                  color="negroYBlanco"
                  type="submit"
                >
                  {loading ? (
                    <SpinnerLlanta />
                  ) : (
                    "Actualizar Informacion de Carro"
                  )}
                </Button>
              </form>
              <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default EditarAutoById;
