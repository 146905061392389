import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Typography } from "@mui/material";
import React, { useState } from "react";
import Footer from "../components/Footer";
import MyNavbar from "../components/NavBar";

const Ubicacion = () => {
  const [activeLocation, setActiveLocation] = useState("Centenario");

  // Información de las ubicaciones
  const locations = {
    Centenario: {
      name: "Av. Centenario",
      address:
        "Avenida Centenario 1002, La Martinica, Álvaro Obregón, C.P. 01619 Ciudad de México, México.",
      phone: ["(+52) 55-7158-1517", "(+52) 55-7158-0234", "(+52) 55-5214-2890"],
      hours: [
        { days: "Lunes a Viernes", hours: "9:00am a 6:00pm" },
        { days: "Sábados", hours: "10:00am a 4:00pm" },
      ],
      mapUrl:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60227.10650280878!2d-99.30286335136715!3d19.360744000000008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d2005c4acf4169%3A0x8eceeaaaa209657d!2sPick%20Uplandia!5e0!3m2!1ses-419!2smx!4v1711515842606!5m2!1ses-419!2smx",
    },
    Tlalnepantla: {
      name: "Tlalnepantla",
      address:
        "Av. Mario Colin 8-A Colonia San Javier, Tlalnepantla de Baz, Estado de México, C.P 54030",
      phone: ["(+52) 55-5390-7328", "(+52) 55-5175-6654", "(+52) 55-9109-1010"],
      hours: [
        { days: "Lunes a Viernes", hours: "9:00am a 6:00pm" },
        { days: "Sábados", hours: "10:00am a 2:00pm" },
      ],
      mapUrl:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.0942372121116!2d-99.21590618468315!3d19.537024686830242!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f92c3b5a6a5f%3A0xa3b7b9c7a7e5a68f!2sAv.%20Mario%20Col%C3%ADn%208-A%2C%20San%20Javier%2C%2054030%20Tlalnepantla%20de%20Baz%2C%20M%C3%A9x.!5e0!3m2!1ses-419!2smx!4v1642610987650!5m2!1ses-419!2smx",
    },
  };
  return (
    <div className="ubicacion">
      <MyNavbar />
      <div
        className="content"
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "3rem",
            gap: "2rem",
          }}
        >
          <Typography variant="h4">
            ¡Visítanos en alguna de nuestras sucursales!
          </Typography>
          <div className="movil" style={{ display: "flex" }}>
            <div className="ubicacionesVertical">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <div
                  onClick={() => setActiveLocation("Centenario")}
                  style={{
                    display: "flex",
                    gap: "2rem",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "#e6e6e6",
                    padding: "1rem",
                    borderRadius: "12px 0px 0px 12px",
                    color:
                      activeLocation === "Centenario"
                        ? "var(--secondaryColor)"
                        : "inherit",
                  }}
                >
                  <Typography variant="h6" style={{ color: "inherit" }}>
                    Av. Centenario
                  </Typography>
                  {activeLocation === "Centenario" ? (
                    <KeyboardArrowLeftIcon style={{ color: "inherit" }} />
                  ) : (
                    <KeyboardArrowRightIcon style={{ color: "inherit" }} />
                  )}
                </div>
                <div
                  onClick={() => setActiveLocation("Tlalnepantla")}
                  style={{
                    display: "flex",
                    gap: "2rem",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "#e6e6e6",
                    padding: "1rem",
                    borderRadius: "12px 0px 0px 12px",
                    color:
                      activeLocation === "Tlalnepantla"
                        ? "var(--secondaryColor)"
                        : "inherit",
                  }}
                >
                  <Typography variant="h6" style={{ color: "inherit" }}>
                    Tlalnepantla
                  </Typography>
                  {activeLocation === "Tlalnepantla" ? (
                    <KeyboardArrowLeftIcon style={{ color: "inherit" }} />
                  ) : (
                    <KeyboardArrowRightIcon style={{ color: "inherit" }} />
                  )}
                </div>
              </div>
            </div>
            <div className="ubicacionesHorizontal">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "0.5rem",
                }}
              >
                <div
                  onClick={() => setActiveLocation("Centenario")}
                  style={{
                    display: "flex",
                    gap: ".5rem",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "#e6e6e6",
                    padding: "1rem",
                    borderRadius: "12px 12px 0px 0px",
                    flexDirection: "column",
                    color:
                      activeLocation === "Centenario"
                        ? "var(--secondaryColor)"
                        : "inherit",
                  }}
                >
                  <Typography variant="h6" style={{ color: "inherit" }}>
                    Av. Centenario
                  </Typography>
                  {activeLocation === "Centenario" ? (
                    <KeyboardArrowUpIcon style={{ color: "inherit" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ color: "inherit" }} />
                  )}
                </div>
                <div
                  onClick={() => setActiveLocation("Tlalnepantla")}
                  style={{
                    display: "flex",
                    gap: ".5rem",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "#e6e6e6",
                    padding: "1rem",
                    borderRadius: "12px 12px 0px 0px",
                    flexDirection: "column",
                    color:
                      activeLocation === "Tlalnepantla"
                        ? "var(--secondaryColor)"
                        : "inherit",
                  }}
                >
                  <Typography variant="h6" style={{ color: "inherit" }}>
                    Tlalnepantla
                  </Typography>
                  {activeLocation === "Tlalnepantla" ? (
                    <KeyboardArrowUpIcon style={{ color: "inherit" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ color: "inherit" }} />
                  )}
                </div>
              </div>
            </div>
            <div className="cards">
              {activeLocation && (
                <div className="info">
                  <div className="texto">
                    <div>
                      <Typography
                        variant="h6"
                        sx={{ color: "var(--secondaryColor)" }}
                      >
                        Pick Uplandia
                      </Typography>
                      <Typography variant="h7">
                        {locations[activeLocation].name}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          gap: "5px",
                        }}
                      >
                        <LocationOnIcon color="rojoYBlanco" />
                        <Typography>
                          {locations[activeLocation].address}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          gap: "5px",
                        }}
                      >
                        <LocalPhoneIcon color="rojoYBlanco" />
                        <div>
                          <Typography>
                            {locations[activeLocation].phone[0]}
                          </Typography>
                          <Typography>
                            {locations[activeLocation].phone[1]}
                          </Typography>
                          <Typography>
                            {locations[activeLocation].phone[2]}
                          </Typography>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          gap: "5px",
                        }}
                      >
                        <CalendarMonthIcon color="rojoYBlanco" />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: ".5rem",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography>
                              {locations[activeLocation].hours[0].days}
                            </Typography>
                            <Typography>
                              {locations[activeLocation].hours[0].hours}
                            </Typography>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography>
                              {locations[activeLocation].hours[1].days}
                            </Typography>
                            <Typography>
                              {locations[activeLocation].hours[1].hours}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <iframe
                    src={locations[activeLocation].mapUrl}
                    width="300px"
                    height="100%"
                    className="mapa"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Ubicacion;
